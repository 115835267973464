import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GenericMenuItem } from "../../generic-menu/generic-menu-item.model";

/**
 * This component is responsible for the display of standardized dialog content. Optionally, generic menu
 * items can be provided, which will be displayed inbetween the title and the close icon button.
 *
 * @export
 */
@Component({
  selector: "hypecast-admin-dialog-header",
  templateUrl: "./dialog-header.component.html",
  styleUrls: ["./dialog-header.component.scss"],
})
export class DialogHeaderComponent {
  // This tooltip text will be displayed inside the tooltip of the close icon button
  @Input() closeTooltipText: string = "Close the dialog";

  @Input() showCloseButton: boolean = true;

  // Determines whether the divider should be visible or not
  @Input() dividerIsVisible: boolean = true;

  // These generic menu items will be displayed in between the title and the close icon button
  @Input() items: GenericMenuItem[] = [];

  @Input() title: string = "";

  @Output() emitCloseClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}
}
