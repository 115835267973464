import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { ImageModule } from '@app/shared/components/image/image.module';
import { UserInfoComponent } from './user-info.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  imports: [
    SharedModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,

    RouterModule,
    ImageModule
  ],
  declarations: [UserInfoComponent],
  exports: [UserInfoComponent]
})
export class UserInfoModule { }
