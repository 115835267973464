export enum LoadingState {
  Initial = "Initial", // No upload state
  Waiting = "Waiting", // Buffering
  Querying = "Querying", // Querying (creating file shell)
  Loading = "Loading", // (Up)loading
  Erroneous = "Erroneous", // If an error occurs
  Completed = "Completed", // When the (up)loading has finished
  Rendering = "Rendering", // Rendering
  Converting = "Converting", // Rendering
}
