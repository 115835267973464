import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsSubmitComponent } from './buttons-submit.component';
import { SharedModule } from '@app/shared/shared.module';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    SharedModule,

    MatProgressSpinnerModule,
    MatRippleModule,
    MatTooltipModule
  ],
  declarations: [ButtonsSubmitComponent],
  exports: [ButtonsSubmitComponent]
})
export class ButtonsSubmitModule { }
