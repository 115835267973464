<button (click)="onClick.emit()" [class.disabled]="disabled === true || isLoading === true"
        [disabled]="disabled === true || isLoading === true"
        [matRippleDisabled]="disabled === true || isLoading === true"
        [matTooltipDisabled]="tooltipDisabled === true" [matTooltip]="tooltipText"
        class="hypecast-primary-button group flex items-center rounded-md text-xl h-12"
        matRipple>
  <span>{{submitButtonText}}</span>
  <div class="flex-initial">
    <ng-content></ng-content>
  </div>
  <div *ngIf="isLoading === true" class="flex-initial ml-4">
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
</button>
