<a *ngIf="showPrivacyPolicyDialog()" class="text-white no-text-decoration" href="https://hypecast.one/privacy/"
   target="_blank">Privacy Policy</a>
<div class="flex flex-row mt-4">
  <!-- POWERED BY -->
  <div class="flex-auto">
    <div class="text-white text-base">Powered by</div>
  </div>

  <!-- LOGO -->
  <div class="flex-initial">
    <img alt="Hype1000" class="w-auto h-6" src="/assets/logos/Hype1000white.svg"/>
  </div>
</div>
