import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HypecastAuthenticationService } from '@app/shared/authentication/services/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { Guid } from '@app/shared/functions/guid';
import { __ } from '@app/shared/functions/object.functions';
import { ApiUrlService } from '@app/shared/services/api-url.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'hypecast-admin-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent extends BaseComponent implements OnInit, OnDestroy {
  private _src: string;
  @Input()
  get src() {
    return this._src;
  }
  set src(source: string) {
    const hasChanged = this._src !== source;

    this._src = source;
    if (this.allowDynamicSetting && hasChanged === true) {
      this.loadImage();
    }
  }

  private _type: string;
  @Input()
  get type(): string {
    return this._type;
  }
  set type(type: string) {
    this._type = type;
  }

  @Input() loadingContainerClass: string = '';

  @Input() customClass: string = 'image';

  @Input() loadingClass: string = '';

  @Input() height: string = 'auto';

  @Input() defaultImage: string = '';

  @Input() width: string = 'auto';

  @Input() minHeight: string = 'initial';

  @Output() isLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('image', { static: true }) image: ElementRef;

  isLoading: boolean = true;

  allowDynamicSetting = false;

  objectUrl: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: HypecastAuthenticationService,
    private apiUrlService: ApiUrlService,
    private httpClient: HttpClient
  ) {
    super();
  }

  ngOnInit() {
    this.loadImage();
    this.allowDynamicSetting = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    const urlCreator = window.URL || window.webkitURL;
    urlCreator.revokeObjectURL(this.objectUrl);
  }

  private loadImage() {
    let alreadyLoaded = false;

    let alreadyErroneous = false;

    if (!__.IsNullOrUndefinedOrEmpty(this.src) && this.src.split(".")[0].length === Guid.Empty.length) {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${this.authenticationService.getAccessToken()}`,
        'Cache-Control': `private, max-age=10000000`
      });

      const options: {
        headers?: HttpHeaders;
        observe?: 'body';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType: any,
        withCredentials?: boolean;
      } = {
        responseType: 'blob',
        headers
      };

      return this.httpClient
        .disableApiPrefix()
        .skipErrorHandler()
        .get(
          `${this.apiUrlService.getCdnUrl()}api/v1/files/${this.src}`,
          options
        )
        .pipe(
          map((file: ArrayBuffer) => {
            return new Blob([file]);
          })
        )
        .subscribe({
          next: (image: Blob) => {
            const urlCreator = window.URL || window.webkitURL;
            this.objectUrl = urlCreator.createObjectURL(image);
            this.image.nativeElement.src = this.objectUrl;
            setTimeout(() => {
              this.isLoading = false;
              this.isLoaded.emit(true);
              this.cdr.detectChanges();
            }, 1)
          },
          error: (error: any) => {
            setTimeout(() => {
              this.isLoading = false;
              this.isLoaded.emit(true);
              this.cdr.detectChanges();
            }, 1)
          }
        })
    }

    this.image.nativeElement.onload = () => {
      this.isLoading = false;
      this.isLoaded.emit(true);
      this.cdr.detectChanges();
    };
    this.image.nativeElement.onerror = () => {
      if (alreadyLoaded === true) {
        if (alreadyErroneous === false) {
          this.image.nativeElement.src = 'assets/1x1.png';
          alreadyErroneous = true;
        }
      } else {
        alreadyLoaded = true;
        this.image.nativeElement.src = this.defaultImage;
      }
      this.cdr.detectChanges();
    };

    // Image by source
    if (__.IsNullOrUndefinedOrEmpty(this.src)) {
      this.image.nativeElement.src = this.defaultImage;
    } else {
      if (this.src.length !== Guid.Empty.length) {
        this.image.nativeElement.src = this.src;
      }
    }
  }

}
