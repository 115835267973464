import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { SharedModule } from '@app/shared/shared.module';

import { ButtonsCancelComponent } from './buttons-cancel.component';

@NgModule({
  imports: [
    SharedModule,
    MatRippleModule
  ],
  declarations: [ButtonsCancelComponent],
  exports: [ButtonsCancelComponent]
})
export class ButtonsCancelModule { }
