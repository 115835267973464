import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { __ } from '@app/shared/functions/object.functions';

export class RouteService {
  static MergeUrlSegments(snapshot: ActivatedRouteSnapshot): UrlSegment[] {
    let urlSegments: UrlSegment[] = [];

    if (!__.IsNullOrUndefined(snapshot)) {
      urlSegments = [...snapshot.url, ...RouteService.MergeUrlSegments(snapshot.firstChild)];
      return urlSegments;
    } else {
      return urlSegments;
    }
  }

  static MergeData(snapshot: ActivatedRouteSnapshot): any {
    const data = {};
    let route = snapshot;
    Object.assign(data, { ...route.data });

    while (route.firstChild) {
      route = route.firstChild;
      Object.assign(data, { ...route.data });
    }
    return data;
  }

  static MergeParams(snapshot: ActivatedRouteSnapshot): any {
    const params = {};
    let route = snapshot;
    Object.assign(params, { ...route.params });

    while (route.parent) {
      route = route.parent;
      Object.assign(params, { ...route.params });
    }
    return params;
  }
}