import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActionsComponent } from './actions.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule
  ],
  declarations: [ActionsComponent],
  exports: [ActionsComponent]
})
export class ActionsModule { }
