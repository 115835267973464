import { Injectable } from '@angular/core';
import { asyncScheduler, BehaviorSubject } from "rxjs";

/**
 * A service to be used with the base-layout component to provide child views with an ability
 * to specify that an app's content container should not stretch beyond 100% of the screen height
 */
@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private _vh100ContentSection$ = new BehaviorSubject<boolean>(false);
  readonly vh100ContentSection$ = this._vh100ContentSection$.asObservable();

  set vhFullContentSection(isFullHeight: boolean) {
    asyncScheduler.schedule(() => this._vh100ContentSection$.next(isFullHeight))
  }
}
