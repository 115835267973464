import { Directive, Input, TemplateRef, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { LoadingComponent } from './loading.component';
import { __ } from '@app/shared/functions/object.functions';

@Directive({
    selector: '[loading]'
})
export class LoadingDirective {

    private wrapperContainer: ComponentRef<LoadingComponent>;

    private isLoading: boolean = false;

    private cssClass: string = '';

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    @Input() set loading(isLoading: boolean) {
        this.isLoading = isLoading;
        this.renderLoading();
    }

    @Input() set loadingClass(loadingClass: string) {
        this.cssClass = loadingClass;
        this.renderLoading();
    }

    private renderLoading() {
        if (__.IsNullOrUndefined(this.isLoading) && __.IsNullOrUndefined(this.loadingClass)) {
            return;
        }

        if (__.IsNullOrUndefined(this.wrapperContainer)) {
            const containerFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
            this.wrapperContainer = this.viewContainerRef.createComponent(containerFactory);
        }

        this.wrapperContainer.instance.templateRef = this.templateRef;
        this.wrapperContainer.instance.isLoading = this.isLoading;
        this.wrapperContainer.instance.cssClass = this.cssClass;
    }
}
