import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '@app/shared';

import {ActionsModule} from './actions/actions.module';
import {BaseLayoutComponent} from './base-layout.component';
import {NavigationModule} from './navigation/navigation.module';
import {UserInfoModule} from './user-info/user-info.module';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    SharedModule,
    UserInfoModule,
    ActionsModule,
    NavigationModule,
    RouterModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule
  ],
  declarations: [
    BaseLayoutComponent,
  ]
})
export class BaseLayoutModule {
}
