import { Component } from '@angular/core';

/**
 * This component is responsible for the display of standardized footer actions
 *
 * @export
 */
@Component({
  selector: 'hypecast-admin-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrls: ['./dialog-footer.component.scss']
})
export class DialogFooterComponent { }

