<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false"
               [disableClose]="isSidebarNonClosable$ | async"
               class="sidenav"
               fixedInViewport>
    <!-- SIDE BAR -->
    <div class="side-navigation hide-scrollbars">

      <!-- side-bar__user-info -->
      <div class="flex-initial p-5 pb-4 pl-6 pr-6">
        <hypecast-admin-user-info [showTenantOnly]="true"></hypecast-admin-user-info>
      </div> <!-- /side-bar__user-info -->

      <!-- side-bar__navigation -->
      <div class="flex-auto p-5 pt-6 pb-6">
        <hypecast-admin-navigation></hypecast-admin-navigation>
      </div> <!-- /side-bar__navigation -->

      <!-- side-bar__actions -->
      <div class="bottom p-4 pl-6 pr-6">
        <hypecast-admin-actions></hypecast-admin-actions>
      </div> <!-- /side-bar__actions -->

    </div> <!-- /side-bar -->

    <!-- SPACER -->
    <div class="flex-initial" style="min-width: 17rem; max-width: 17rem">
    </div>
  </mat-sidenav>
  <mat-sidenav-content>


    <!-- CONTENT -->
    <div
      [class.content-height-100vh]="vh100ContentSection$ | async"
      class="bg-white pl-12 pt-9 pr-12">
      <button (click)="drawer.toggle()"
              *ngIf="isHandset$ | async"
              aria-label="Toggle sidenav"
              mat-icon-button
              type="button">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <router-outlet></router-outlet>
    </div>
    <!-- /content -->
  </mat-sidenav-content>
</mat-sidenav-container>

