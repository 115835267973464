import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OAuthService } from '@app/shared/authentication/angular-oauth-oidc/oauth-service';
import {
  HypecastAuthenticationService,
  HypecastCredentials,
} from '@app/shared/authentication/services/authentication.service';
import { __ } from '@app/shared/functions/object.functions';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Logger } from '../logger.service';
import { RouteService } from '../route.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard  {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: HypecastAuthenticationService,
    private toastr: ToastrService,
    private oauthService: OAuthService,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const data = RouteService.MergeData(route);

    return this.authenticationService
      .credentials$
      .pipe(
        map((value: HypecastCredentials) => {
          if (!__.IsNullOrUndefined(data['roles'])) {
            const allowed = this.authenticationService.hasAnyRole(data['roles']);
            if (allowed) {
              return true;
            } else {
              // TODO: What to do on forbidden case?
              // return this.checkForbidden(route);
            }
          }

          return !__.IsNullOrUndefined(value)
        })
      );
  }
}
