<div class="flex flex-row">

  <!-- USER NAME -->
  <div *ngIf="showTenantOnly === false" class="user-info-user-name">
    <strong>{{ authenticationService?.user?.firstname }}</strong>
  </div> <!-- /user-name -->

  <!-- COMPANY CLAIM -->
  <div *ngIf="showTenantOnly === true" class="flex-auto flex flex-row items-center justify-center mt-5">
    <!-- LOGO -->
    <div *ngIf="!IsNullOrUndefinedOrEmpty(tenant?.logoUrl) ;else companyName"
         class="flex-initial flex flex-row items-center justify-center">
      <hypecast-admin-image [height]="'50px'"
                            [loadingClass]="'hypecast-admin-table__row__column__thumbnail__image-container--loading--45'"
                            [src]="tenant?.logoUrl">
      </hypecast-admin-image>
    </div>
    <!-- /logo -->
  </div>

  <!-- COPMANY NAME -->
  <ng-template #companyName>
    <div class="flex-auto text-lg text-white text-center mt-2" style="font-family: Spartan-Bold;">
      {{ tenant?.companyName }}
    </div>
  </ng-template>
  <!-- /company-name -->
  <!-- /company-claim -->

  <!-- ADDITIONAL ACTIONS -->
  <div *ngIf="showTenantOnly === false" class="flex-initial">
    <button [matMenuTriggerFor]="actions" class="button" mat-mdc-icon-button type="button">
      <mat-icon class="text-black icon" svgIcon="arrow_down"></mat-icon>
    </button>
    <mat-menu #actions="matMenu">
      <button [routerLink]="'/users/' + user?.id" mat-menu-item>Profile</button>
      <div class="bg-secondary-blue" style="height: 1px; width: 100%;"></div>
      <button (click)="logout()" mat-menu-item>Logout</button>
    </mat-menu>
  </div> <!-- additional-actions -->

</div>

<div *ngIf="showTenantOnly === false" class="flex flex-row mt-2">
  <div class="flex flex-row text-sm flex-auto items-center hide-on-mobile">
    <ng-container *ngFor="let role of user?.roles; trackBy: trackByRole; let last = last">
      {{ RoleHelper.GetExtendedRoleByValueOrNull(role)?.displayName }}
      <ng-container *ngIf="last !== true">,
      </ng-container>
    </ng-container>
  </div>
</div>
