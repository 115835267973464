import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';

import { FileContainerBase } from './models/FileContainer';
import { LoadingState } from './models/LoadingState.enum';

/**
 * This service provides useful functionalities regarding e.g. file handling, transformation,
 * loading, previewing or uploading
 *
 * @export
 * @extends {BaseService}
 * @template T The type of the file shell that the file service creates for each file container
 */
export class FileUploadService<T> extends BaseService {
  private httpClient: HttpClient;

  private fileContainers: FileContainerBase[] = [];

  static mapHttpEventToProgress(event: any): FileUploadProgress {
    if (event.type === HttpEventType.UploadProgress) {
      return new FileUploadProgress(Math.round(100 * event.loaded / event.total), LoadingState.Loading);
    } else if (event instanceof HttpResponse) {
      return new FileUploadProgress(100, LoadingState.Completed);
    }
    return new FileUploadProgress(0, LoadingState.Querying);
  }

  /**
   * Creates an instance of FileUploadService.
   * @param fileShellUrl The absolute REST endpoint where the file service can create a file shell for each file
   * @param filesUrl The absolute REST endpoint (without the id parameter) where the file service can post a file to
   * @param chunkSize The size of chunks for the chunked upload
   * @param fileShellIdSelector The id selector for the file shell
   * @param injector Base injector instance to fetch services
   */
  constructor(
    protected fileShellUrl: string,
    protected filesUrl: string,
    protected chunkSize: number,
    protected fileShellIdSelector: (fileShell: T) => string,
    protected injector: Injector
  ) {
    super();

    this.httpClient = this.injector.get(HttpClient);

    if (__.IsNullOrUndefined(this.chunkSize)) {
      this.chunkSize = 1024 * 1024 * 128; // 128 KB
    }
  }
}

export class FileUploadProgress {
  progress: number = 0;

  state: LoadingState = LoadingState.Initial;

  constructor(_progress: number, _state: LoadingState) {
    this.progress = _progress;
    this.state = _state;
  }
}