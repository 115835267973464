import { Component, OnInit } from "@angular/core";
import { ITenantService } from "@app/shared/services/itenant.service";

@Component({
  selector: "hypecast-admin-actions",
  templateUrl: "./actions.component.html",
  styleUrls: ["./actions.component.scss"],
})
export class ActionsComponent implements OnInit {
  tenantsWithPrivacyPolicy: string[] = ["eon"];

  constructor(private tenantService: ITenantService) {}

  ngOnInit() {}

  showPrivacyPolicyDialog() {
    return this.tenantsWithPrivacyPolicy.includes(this.tenantService.getTenantName());
  }
}
