import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogContentComponent } from './dialog-content.component';
import { MatDialogModule } from '@angular/material/dialog';

/**
 * This module is responsible for the export of the mb dialog content component
 *
 * @export
 */
@NgModule({
  imports: [
    CommonModule,

    MatDialogModule
  ],
  declarations: [DialogContentComponent],
  exports: [DialogContentComponent]
})
export class DialogContentModule { }
