import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { environment } from "@env/environment";
import { merge } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { Logger } from "./core/logger.service";
import { BaseComponent } from "./shared/base/components/base-component";
import { Tenant } from "./shared/models/classes/Tenant";
import { ApiUrlService } from "./shared/services/api-url.service";
import { ForbiddenService } from "./shared/services/forbidden.service";
import { ITenantService } from "./shared/services/itenant.service";
import { datadogLogs } from "@datadog/browser-logs";

const log = new Logger("App");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends BaseComponent implements OnInit {
  tenant: Tenant;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tenantService: ITenantService,
    private apiUrlService: ApiUrlService,
    private forbiddenService: ForbiddenService
  ) {
    super();
  }

  ngOnInit() {
    super.addSubscription(
      this.tenantService.getTenant$().subscribe({
        next: (tenant: Tenant) => {
          this.tenant = tenant;
          this.apiUrlService.getTenant = () => tenant;

          datadogLogs.setGlobalContext({
            application: tenant.tenantName + " Admin Console",
            tenant: tenant.tenantName,
          });
        },
      })
    );

    if (environment.production) {
      Logger.enableProductionMode();
    }

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    const onNavigationStart = this.router.events.pipe(filter((event) => event instanceof NavigationStart));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationStart).subscribe((event) => {
      this.forbiddenService.allow();
    });

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event["title"];
        if (title) {
          this.titleService.setTitle(title);
        }
      });
      
      this.clearTableRelatedLocalStorage();
  }

    // Function to clear local storage items that include 'table' in their key
    clearTableRelatedLocalStorage() {
      // Get all keys from localStorage
      const keys = Object.keys(localStorage);
      
      // Loop through each key
      keys.forEach(key => {
        // If the key contains 'table', remove the item
        if (key.includes('table')) {
          localStorage.removeItem(key);
          console.log(`Removed localStorage item: ${key}`);
        }
      });
    }
}


