<div class="dialog-header">

  <div class="flex gap-6">

    <!-- TITLE -->
    <h2 class="dialog-header__title flex-auto">
      {{ title }}
    </h2> <!-- /dialog-header__title -->

    <!-- OPTIONAL ACTION BUTTONS -->
    <div *ngIf="items.length > 0" class="dialog-header__actions flex-none">
      <hypecast-admin-generic-actions [items]="items"></hypecast-admin-generic-actions>
    </div> <!-- /dialog-header__actions -->
    <div *ngIf="items.length === 0" class="dialog-header__actions flex-none">
      <ng-content></ng-content>
    </div> <!-- /dialog-header__actions -->

    <!-- CLOSE ICON BUTTON -->
    <div *ngIf="showCloseButton" class="dialog-header__close-icon-button  flex-shrink justify-end">
      <button (click)="emitCloseClick.emit(true)" [matTooltip]="closeTooltipText" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div> <!-- /dialog-header__close-icon-button -->

  </div>

  <!-- DIVIDER -->
  <mat-divider *ngIf="dividerIsVisible" class="dialog-header__divider dialog-header__divider--stretched"></mat-divider>

</div>
