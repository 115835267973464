/**
 * GUID Typescript helper
 *
 * @export
 */
export class Guid {
  /**
   * Microsoft representation of an empty GUID
   *
   * @memberof Guid
   */
  static Empty = '00000000-0000-0000-0000-000000000000';

  /**
   * "C# GUIDs are guaranteed to be unique. This solution is very likely to be unique.
   * This generated key is great to use as a temporary key, not as a real GUID."
   *
   * Stolen from https://stackoverflow.com/questions/26501688/a-typescript-guid-class
   *
   * @static
   * @return Returns a new, very likely to be unique, GUID
   * @memberof Guid
   *
   * @example let guid = Guid.NewGuid();
   */
  static NewGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c: any) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
