import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {SharedModule} from '@app/shared';

import {NavigationLinkComponent} from './navigation-link.component';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    RouterModule
  ],
  declarations: [NavigationLinkComponent],
  exports: [NavigationLinkComponent]
})
export class NavigationLinkModule {
}
