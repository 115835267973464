import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { Tenant } from '@app/shared/models/classes/Tenant';
import { ITenantService } from '@app/shared/services/itenant.service';

import { OAuthService } from '../angular-oauth-oidc/oauth-service';
import { HypecastOAuthConfig } from '../models/hypecast-oauth-config';
import { HYPECAST_OAUTH_CONFIG } from '../tokens/hypecast-oauth-config.token';
import { HypecastAuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class HypecastOAuthService extends BaseService {

    constructor(
        private oauthService: OAuthService,
        @Inject(HYPECAST_OAUTH_CONFIG) private oAuthConfig: HypecastOAuthConfig,
        private route: ActivatedRoute,
        private location: Location,
        private tenantService: ITenantService,
        private authenticationService: HypecastAuthenticationService
    ) {
        super();

        this.oauthService.configure(this.oAuthConfig);
        this.initialize();
    }

    getIdentityClaims(): object {
        return this.oauthService.getIdentityClaims();
    }

    getIdToken(): string {
        return this.oauthService.getIdToken();
    }

    /**
     * Returns the current access_token.
     */
    getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    /**
     * Returns the current refresh_token.
     */
    getRefreshToken(): string {
        return this.oauthService.getRefreshToken();
    }

    /**
     * Returns the expiration date of the access_token
     * as milliseconds since 1970.
     */
    getAccessTokenExpiration(): number {
        return this.oauthService.getAccessTokenExpiration();
    }

    /**
     * Returns the expiration date of the id_token
     * as milliseconds since 1970.
     */
    getIdTokenExpiration(): number {
        return this.oauthService.getAccessTokenExpiration();
    }

    /**
     * Checkes, whether there is a valid access_token.
     */
    hasValidAccessToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    /**
     * Checks whether there is a valid id_token.
     */
    hasValidIdToken(): boolean {
        return this.oauthService.hasValidIdToken();
    }

    /**
     * Retrieve a saved custom property of the TokenReponse object. Only if predefined in authconfig.
     */

    logOut(): void {
        this.oauthService.logOut();
    }

    login() {
        if (window.location.href.includes('skipAuthentication')) {
            return;
        }

        let chain: Promise<any> = null;

        if (__.IsNullOrUndefined(this.tenantService.getTenant())) {
            chain = this.tenantService.getTenant$().toPromise()
                // Load discovery document
                .then((q: Tenant) => {
                    this.oAuthConfig.clientId = q.clientId;
                    this.oauthService.clientId = q.clientId;
                    return this.oauthService.loadDiscoveryDocument()
                });
        } else {
            this.oAuthConfig.clientId = this.tenantService.getTenant().clientId;
            this.oauthService.clientId = this.tenantService.getTenant().clientId;
            chain = this.oauthService.loadDiscoveryDocument();
        }

        return chain
            .then((q: any) => {
                if (!__.IsNullOrUndefinedOrEmpty(this.oauthService.getRefreshToken())) {
                    // A refresh token is present, so try to refresh
                    return this.refresh();
                }
                // No refresh token is present
                if (window.location.search.indexOf('code=') === -1) {
                    // If no code flow has previously been initialized
                    this.oauthService.initCodeFlow();
                    return Promise.resolve<boolean>(false);
                } else {
                    // If the code flow has previously been initialized
                    return this.oauthService.tryLogin();
                }
            })
            .then((q: any) => {
                if (q === false) {
                    // The operation has been unsuccessful
                    // TODO: What should we do in this case?
                    return Promise.reject(false);
                }
                // The operation was successful

                if (this.oauthService.hasValidAccessToken()) {
                    // An access token is present
                    return Promise.resolve(true);
                }

                // If all fails, try again a refresh
                return this.refresh();
            })
            .then(() => {
                // Make sure the flow has been completed properly
                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    let stateUrl = this.oauthService.state;
                    if (stateUrl.startsWith('/') === false) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
                }
            })
            .then(() => {
                // Initialize any tasks from the authentication service
                this.authenticationService.init();
            });
    }

    private initialize(): void {
        if (!this.oauthService.hasValidAccessToken()) {
            // No valid access token is present

            this.login();
        } else {
            // An access token is present, initialize any tasks from the authentication service.
            // This will be skipped if the local storage already contains information
            this.authenticationService.init();
        }
    }

    private refresh(): Promise<boolean> {
        return this.oauthService.refreshToken()
            .then(() => Promise.resolve(true))
            .catch(result => {

                // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                const errorResponsesRequiringUserInteraction = [
                    'interaction_required',
                    'token_refresh_error',
                    'login_required',
                    'account_selection_required',
                    'consent_required',
                    'refresh token'
                ];

                if (result
                    && result.error
                    && errorResponsesRequiringUserInteraction.findIndex(q => result.error.error_description.indexOf(q)) >= 0) {

                    // Logout to restart the flow
                    this.oauthService.logOut(false);

                    // Restart the code flow
                    this.oauthService.initCodeFlow();

                    console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                    return Promise.resolve(false);
                }

                // An unknown error occured
                // TODO: What should we do?
                return Promise.reject(false);
            });
    }

}
