import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "@app/shared/base/components/base-component";
import { ForbiddenService } from "@app/shared/services/forbidden.service";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { LayoutService } from "@app/core/layout.service";
import { IPendo } from "ngx-pendo";
import { ITenantService } from "@app/shared/services/itenant.service";
import { HypecastAuthenticationService } from "@app/shared/authentication/services/authentication.service";

declare var pendo: IPendo;

const nonClosableSidebarMediaQuery = "(min-width: 960px)";

@Component({
  selector: "hypecast-admin-base-layout",
  templateUrl: "./base-layout.component.html",
  styleUrls: ["./base-layout.component.scss"],
})
export class BaseLayoutComponent extends BaseComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );
  readonly isSidebarNonClosable$ = this.breakpointObserver
    .observe(nonClosableSidebarMediaQuery)
    .pipe(map(({ matches }) => matches));
  readonly vh100ContentSection$: Observable<boolean>;

  isForbidden: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private forbiddenService: ForbiddenService,
    private layoutService: LayoutService,
    private authenticationService: HypecastAuthenticationService,
    private tenantService: ITenantService
  ) {
    super();

    this.vh100ContentSection$ = this.layoutService.vh100ContentSection$;
  }

  ngOnInit() {
    let tenant = this.tenantService.getTenant();
    let user = this.authenticationService.user;

    // pendo.initialize({
    //   visitor: {
    //     id: user.id || "default-id", // Use user.id or a default id
    //     name: `${user.firstname} ${user.lastname}` || "default-name", // Use user.name or a default name`
    //     email: user.email || "default-email@example.com", // Use user.email or a default email
    //     role: "Viewer",
    //   },
    //   account: {
    //     id: tenant.clientId,
    //     name: tenant.tenantName,
    //   },
    //   apiKey: "26edc181-cd3f-4926-5d51-a148c6eefdab",
    // });

    super.addSubscription(
      this.forbiddenService.isForbidden$.subscribe((isForbidden: boolean) => {
        this.isForbidden = isForbidden;
      })
    );
  }
}
