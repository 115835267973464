import { Component, Input } from "@angular/core";
import { __ } from "@app/shared/functions/object.functions";

@Component({
  selector: "hypecast-admin-navigation-link",
  templateUrl: "./navigation-link.component.html",
  styleUrls: ["./navigation-link.component.scss"],
})
export class NavigationLinkComponent {
  @Input() link: string;

  @Input() icon: string;

  @Input() displayName: string;

  @Input() tag: string;

  @Input() externalLink: boolean = false;
  protected readonly __ = __;
}
