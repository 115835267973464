import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "@app/shared/authentication/angular-oauth-oidc/oauth-service";
import { HypecastOAuthConfig } from "@app/shared/authentication/models/hypecast-oauth-config";
import {
  HypecastAuthenticationService,
  HypecastCredentials,
} from "@app/shared/authentication/services/authentication.service";
import { HypecastOAuthService } from "@app/shared/authentication/services/hypecast-oauth.service";
import { BaseComponent } from "@app/shared/base/components/base-component";
import { __ } from "@app/shared/functions/object.functions";
import { ApplicationUser } from "@app/shared/models/classes/ApplicationUser";
import { Tenant } from "@app/shared/models/classes/Tenant";
import { RoleHelper } from "@app/shared/models/local/role.helper";
import { ITenantService } from "@app/shared/services/itenant.service";

@Component({
  selector: "hypecast-admin-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent extends BaseComponent implements OnInit {
  user: ApplicationUser;

  RoleHelper = RoleHelper;

  tenant: Tenant;

  constructor(
    private oAuthService: OAuthService,
    private authenticationService: HypecastAuthenticationService,
    private hypecastOAuthService: HypecastOAuthService,
    private router: Router,
    private tenantService: ITenantService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  private _showTenantOnly: boolean = false;

  @Input()
  get showTenantOnly(): boolean {
    return this._showTenantOnly;
  }

  set showTenantOnly(showTenantOnly: boolean) {
    this._showTenantOnly = showTenantOnly;
  }

  ngOnInit() {
    super.addSubscription(
      this.authenticationService.credentials$.subscribe({
        next: (credentials: HypecastCredentials) => {
          this.user = credentials.user;
          this.cdr.detectChanges();
        },
      })
    );
    super.addSubscription(
      this.tenantService.getUpdateTenant$().subscribe({
        next: (tenant: Tenant) => {
          if (!__.IsNullOrUndefined(tenant)) {
            this.tenant = tenant;
            this.cdr.detectChanges();
          }
        },
      })
    );
    this.user = this.authenticationService.user;
    this.tenant = this.tenantService.getTenant();
    this.cdr.detectChanges();
  }

  /**
   * Logs the current user out (destroys the locally storaged items) and redirects
   * the user to the login page.
   */
  logout() {
    this.tenantService.resetTenant();

    this.oAuthService.config.openUri = new HypecastOAuthConfig().openUri;
    this.hypecastOAuthService.logOut();
    this.authenticationService.logout();
  }

  trackByRole: (role: string) => string = (entity: string) => entity;
}
