<ng-container *ngFor="let item of actions">
  <ng-container *ngIf="checkIsVisible(item) === true">
    <button [attr.data-test-name]="item.identifier" [disabled]="checkIsLoading(item) || checkDisabled(item)"
      mat-raised-button [color]="item.color" type="submit" (click)="itemClick(item)" ngStyle.xs="margin-bottom: 12px; min-width: 150px">
      <div class="flex gap-6">
        <div class="flex-auto">
          {{ item.displayName }}
        </div>
        <div class="flex-auto" style="padding-top: 8px;" *ngIf="checkIsLoading(item)">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </button>
  </ng-container>
</ng-container>