export enum AppRoute {
  Login = "login",
  Users = "users",
  Groups = "groups",
  Forbidden = "forbidden",
  Podcasts = "podcasts",
  Studios = "studios",
  Editor = "editor",
  VideoClipList = "videoClipList",
  AudioAI = "audio-ai",
  Dashboard = "dashboard",
  Analytics = "analytics",
  Profile = "profile",
  Settings = "settings",
}

export class AppRouteTranslationMap {
  static Translations = {
    podcasts: "Podcasts",
    studios: "Studios",
    editor: "Editor",
    videoClipList: "videoClipList",
    audioai: "AudioAI",
    users: "Users",
    dashboard: "Dashboard",
    analytics: "Analytics",
    profile: "Profile",
    settings: "Settings",
  };
}
