import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { NavigationLinkModule } from './link/navigation-link.module';

import { NavigationComponent } from './navigation.component';

@NgModule({
    imports: [
        SharedModule,

        MatIconModule,

        RouterModule,
        NavigationLinkModule
    ],
    declarations: [NavigationComponent],
    exports: [NavigationComponent]
})
export class NavigationModule { }
