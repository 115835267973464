<a *ngIf="!externalLink" [routerLink]="'/' + link"
   class="transition-all group flex flex-row p-2 pl-2 items-center no-underline"
   routerLinkActive="active">
  <div class="flex-initial flex flex-row items-center text-white ml-3 transition-all">
    <mat-icon [svgIcon]="icon" class="icon"></mat-icon>
  </div>
  <ng-container *ngIf="__.IsNullOrUndefinedOrEmpty(tag)">
    <div
      class="display-name text-lg flex-auto text-white no-underline ml-5 transition-all">
      {{ displayName }}
    </div>
  </ng-container>
  <ng-container *ngIf="!__.IsNullOrUndefinedOrEmpty(tag)">
    <div class="display-name text-lg flex-auto text-white no-underline ml-5 transition-all">
      {{ displayName }}
      <sup>{{ tag }}</sup>
    </div>
  </ng-container>
</a>

<a *ngIf="externalLink" [href]="link" class="transition-all group flex flex-row p-2 pl-2 items-center no-underline"
   rel="noopener noreferrer"
   routerLinkActive="active"
   target="_blank">
  <div class="flex-initial flex flex-row items-center text-white ml-3 transition-all">
    <mat-icon [svgIcon]="icon" class="icon"></mat-icon>
  </div>
  <div class="display-name text-lg flex-auto text-white no-underline ml-5 transition-all">
    {{ displayName }}
    <sup>
      <mat-icon class="sup-icon">open_in_new</mat-icon>
    </sup>
  </div>
</a>

