import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { Role } from '@app/shared/models/classes/Role';
import { Tenant } from '@app/shared/models/classes/Tenant';
import { ApiUrlService } from '@app/shared/services/api-url.service';
import { ITenantService } from '@app/shared/services/itenant.service';
import { environment } from '@env/environment';
import { Observable, Subject, Subscriber } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { OAuthService } from '../angular-oauth-oidc/oauth-service';
import { HypecastOAuthConfig } from '../models/hypecast-oauth-config';

const credentialsKey = 'hypecast-credentials';

export class HypecastCredentials {
    user: ApplicationUser;
    data: {
        [key: string]: number | string | boolean;
    };
}

/**
 * Provides a base for authentication workflow.
 */
@Injectable()
export class HypecastAuthenticationService extends BaseService {

    private _credentials: HypecastCredentials | null;

    get credentials(): HypecastCredentials | null {
        return this._credentials;
    }

    private _credentials$: Subject<HypecastCredentials> = new Subject<HypecastCredentials>();

    credentials$: Observable<HypecastCredentials>;

    private httpClient: HttpClient;

    constructor(
        private httpHandler: HttpHandler,
        private oAuthService: OAuthService,
        private tenantService: ITenantService,
        private apiUrlService: ApiUrlService
    ) {
        super();

        const injector = Injector.create(
            {
                providers: [
                    { provide: HttpClient, useClass: HttpClient, deps: [HttpHandler] },
                    { provide: HttpHandler, useValue: httpHandler }
                ]
            }
        );

        this.httpClient = injector.get(HttpClient);

        this.credentials$ = new Observable<HypecastCredentials>((subscriber: Subscriber<HypecastCredentials>) => {
            if (this.oAuthService.hasValidAccessToken()) {
                // User has a valid access token

                if (!__.IsNullOrUndefined(this._credentials)) {
                    // If the credentials have already been set
                    subscriber.next(this._credentials);
                    return;
                } else {
                    // No credentials have been set

                    const item = JSON.parse(localStorage.getItem(credentialsKey)) as HypecastCredentials;

                    if (!__.IsNullOrUndefined(item)) {
                        // There are credentials in the storage
                        // TODO: Potential for security breach, since stored credendtials could be old ones from a previous user?

                        this._credentials = item;
                        subscriber.next(this._credentials);
                    } else {
                        super.addSubscription(
                            this._credentials$.subscribe({
                                next: (credentials: HypecastCredentials) => {
                                    subscriber.next(credentials);
                                },
                                error: (error: HttpErrorResponse) => {
                                    subscriber.error(error);
                                }
                            })
                        );
                    }
                }

                return;
            }

            // No valid access token exists
            super.addSubscription(
                this._credentials$.subscribe({
                    next: (credentials: HypecastCredentials) => {
                        subscriber.next(credentials);
                    },
                    error: (error: HttpErrorResponse) => {
                        subscriber.error(error);
                    }
                })
            );
        });
    }

    checkIfAdminOrLogOut(roles: string[]) {
        if (roles.findIndex(q => q.includes('Admin')) === -1) {
            this.tenantService.resetTenant();

            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);

            this.oAuthService.config.openUri = new HypecastOAuthConfig().openUri;
            this.oAuthService.logOut({ missingPermissions: true });

            return true;
        }
        return false;
    }

    init(): Observable<HypecastCredentials> {
        if (!this.oAuthService.hasValidAccessToken()) {
            return new Observable(s => {
                s.error({ error: null, message: 'Cannot get user and permissions without valid access token' });
            });
        }

        super.addSubscription(
            this.tenantService.getTenant$()
                .pipe(
                    switchMap((tenant: Tenant) => {
                        return this.getCurrentUser();
                    })
                )
                .subscribe({
                    next: (data: ApplicationUser) => {
                        if (!__.IsNullOrUndefinedOrEmpty(data) && this.checkIfAdminOrLogOut(data.roles)) {
                            return;
                        }

                        const item = JSON.parse(localStorage.getItem(credentialsKey)) as HypecastCredentials;

                        if (!__.IsNullOrUndefined(item)) {
                            // There are credentials in the storage
                            // TODO: Potential for security breach, since stored credendtials could be old ones from a previous user?
                            if (!__.IsNullOrUndefinedOrEmpty(item.user) && this.checkIfAdminOrLogOut(item.user.roles)) {
                                return;
                            }

                            this._credentials = item;
                            this._credentials$.next(this._credentials);
                        }
                        const credentials = Object.assign(new HypecastCredentials(), {
                            user: data,
                            data: this.oAuthService.getIdentityClaims()
                        } as HypecastCredentials);

                        this.saveToStorage(credentials);
                        this._credentials = credentials;
                        this._credentials$.next(credentials);
                    },
                    error: (error: HttpErrorResponse) => {
                        this._credentials$.error(error);
                    }
                })
        );

        return this.credentials$;
    }

    get user(): ApplicationUser {
        return this.credentials.user;
    }

    hasRole(group: string) {
        if (__.IsNullOrUndefinedOrEmpty(group)) {
            return true;
        }
        if (environment.testPermissions === false && environment.production !== true) {
            return true;
        }
        if (!__.IsNullOrUndefined(this.credentials?.user?.roles)) {
            if (this.isAdministrator()) {
                return true;
            }
            return this.credentials?.user?.roles.some(q => q === group);
        }
        return false;
    }

    hasAnyRole(groups: string[]) {
        if (groups.length === 0) {
            return true;
        }
        if (environment.testPermissions === false && environment.production !== true) {
            return true;
        }
        if (!__.IsNullOrUndefined(this.credentials?.user?.roles)) {
            if (this.isAdministrator()) {
                return true;
            }

            return groups.findIndex(a => this.credentials?.user?.roles.some(q => q === a)) > -1;
        }
        return false;
    }

    isAdministrator(): boolean {
        return this.credentials.user.roles.indexOf(Role.Administrator) > -1 || this.credentials.user.roles.indexOf(Role.CompanyAdministrator) > -1;
    }

    isCompanyAdministrator(): boolean {
        return this.credentials.user.roles.indexOf(Role.CompanyAdministrator) > -1;
    }

    isAuthenticated(): boolean {
        return this.oAuthService.hasValidAccessToken();
    }

    getAccessToken(): string {
        return this.oAuthService.getAccessToken();
    }

    logout(): void {
        sessionStorage.removeItem(credentialsKey);
        localStorage.removeItem(credentialsKey);
        this.tenantService.resetTenant();
        return this.oAuthService.logOut();
    }

    private saveToStorage(credentials: HypecastCredentials): void {
        this._credentials = credentials || null;

        if (!__.IsNullOrUndefined(credentials)) {
            const storage = true ? localStorage : sessionStorage;
            storage.setItem(credentialsKey, JSON.stringify(credentials));
        } else {
            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);
        }
    }

    private getCurrentUser(): Observable<ApplicationUser> {
        let headers = new HttpHeaders();
        const token = this.oAuthService.getAccessToken();

        headers = headers.set('Authorization', `Bearer ${token}`);

        return this.httpClient.get<any>(
            `${this.apiUrlService.getApiUrl()}api/v1/users/me`,
            { headers }
        ).pipe(
            map((result: any) => {
                return result.data;
            })
        );
    }
}
