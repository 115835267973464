import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { Route } from "./core/Routes";
import { AppRoute } from "./shared/app.route.enum";

const routes: Routes = [
  // The base layout and authentication takes care of authentication
  // for every request. Use the authentication guard on lower routing levels
  // to enable permission based authorization (see below)
  {
    path: "",
    redirectTo: `/${AppRoute.Dashboard}`,
    pathMatch: "full",
  },
  Route.withBaseLayoutAndAuthentication([
    {
      path: AppRoute.Forbidden,
      loadChildren: () => import("./core/forbidden/forbidden.module").then((m) => m.ForbiddenModule),
    },
    {
      path: AppRoute.Dashboard,
      loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
      path: AppRoute.Analytics,
      loadChildren: () => import("./analytics/analytics.module").then((m) => m.AnalyticsModule),
    },
    {
      path: AppRoute.Podcasts,
      loadChildren: () => import("./podcasts/podcasts.module").then((m) => m.PodcastsModule),
    },
    {
      path: AppRoute.Groups,
      loadChildren: () => import("./groups/groups.module").then((m) => m.GroupsModule),
    },
    {
      path: AppRoute.Users,
      loadChildren: () => import("./users/users.module").then((m) => m.UsersModule),
    },
    {
      path: AppRoute.Settings,
      loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
    },
    {
      path: AppRoute.Studios,
      loadChildren: () => import("./meetings/meetings.module").then((m) => m.MeetingsModule),
    },
    {
      path: AppRoute.AudioAI,
      loadChildren: () => import("./audio-ai/audio-ai.module").then((m) => m.AudioAiModule),
    },
    {
      path: AppRoute.Editor,
      loadChildren: () => import("./editor/editor.module").then((m) => m.EditorModule),
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
