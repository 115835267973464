import { CurrencyPipe, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "@app/shared";
import { environment } from "@env/environment";
import { TranslateModule } from "@ngx-translate/core";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { I18nService } from "./core/i18n.service";
import { AppRoute } from "./shared/app.route.enum";
import { HypecastAuthenticationModule } from "./shared/authentication/authentication.module";
import { HypecastBaseOAuthConfig } from "./shared/authentication/models/HypecastBaseOAuthConfig";
import { HYPECAST_OAUTH_CONFIG } from "./shared/authentication/tokens/hypecast-oauth-config.token";
import { APP_ROUTE, APP_TITLE, BreadcrumbsService } from "./shared/components/breadcrumbs/breadcrumbs.service";
import { ConfigurableTableDialogModule } from "./shared/components/configurable-table-dialog/configurable-table-dialog.module";
import { ITenantService } from "./shared/services/itenant.service";
import { LoadingBarModule } from "./shared/services/local/loading-bar/loading-bar.module";
import { QuillConfigModule } from "ngx-quill";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgxPendoModule } from "ngx-pendo";

registerLocaleData(localeDe);
registerLocaleData(localeEn);

export const MY_FORMATS = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

const maskConfig: Partial<IConfig> = {
  validation: true,
};

export function getCurrentLanguage(i18nService: I18nService) {
  i18nService.init(environment.supportedLanguages);
  return i18nService.language;
}

export function getOAuthConfig(tenantService: ITenantService) {
  return new HypecastBaseOAuthConfig();
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgxMaskModule.forRoot(maskConfig),
    LoadingBarModule.forRoot(),
    FormsModule,
    HttpClientModule,

    CoreModule,
    SharedModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot(),

    MomentDateModule,
    MatDatepickerModule,
    ConfigurableTableDialogModule,

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),

    QuillConfigModule.forRoot({
      modules: {
        toolbar: false,
      },
    }),

    NgxPendoModule.forRoot({
      pendoApiKey: "26edc181-cd3f-4926-5d51-a148c6eefdab",
      pendoIdFormatter: (pendoId: string) => pendoId.toLowerCase(),
    }),

    HypecastAuthenticationModule.forRoot(),

    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    BreadcrumbsService,
    CurrencyPipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: APP_ROUTE, useValue: AppRoute },
    { provide: APP_TITLE, useValue: "Hypecast" },
    { provide: LOCALE_ID, useFactory: getCurrentLanguage, deps: [I18nService] },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: HYPECAST_OAUTH_CONFIG, useFactory: getOAuthConfig, deps: [ITenantService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
