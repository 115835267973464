import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HypecastAuthenticationService } from '@app/shared/authentication/services/authentication.service';
import { HypecastOAuthService } from '@app/shared/authentication/services/hypecast-oauth.service';
import { __ } from '@app/shared/functions/object.functions';
import { Ticket } from '@app/shared/models/classes/Ticket';
import { ForbiddenService } from '@app/shared/services/forbidden.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscriber, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private forbiddenService: ForbiddenService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticationService = this.injector.get(HypecastAuthenticationService);
    const oauthService = this.injector.get(HypecastOAuthService);

    return next.handle(request.clone()).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401 && request.method.toLowerCase() !== 'options') {

          // TODO: QUEUE MULTIPLE REQUEST AND JUST SEND OUT ONE TOKEN REQUEST
          return new Observable<any>((subscriber: Subscriber<any>) => {
            oauthService.login().then((data: any) => {
              subscriber.next(data);
              subscriber.complete();
            }).catch((error: any) => {
              subscriber.error(error);
            })
          }).pipe(mergeMap((accessToken: Ticket) => {
            return next.handle(
              request.clone({
                setHeaders: { Authorization: `Bearer ${authenticationService.getAccessToken()}` }
              })
            );
          }),
            catchError((error: HttpErrorResponse) => {
              switch (error.status) {
                case 400:
                case 401:
                case 403:
                  if (__.IsNullOrUndefined(error.error) || __.IsNullOrUndefinedOrEmpty(error.error.error)) {
                    break;
                  }
                  authenticationService.logout();
                  this.toastr.error('You are not authorized to view this site. Please login.');
                  this.router.navigate(['/login']).then((data: any) => {
                  });

                  return throwError(response);

                default:
                  break;
              }

              return of(null);
            })
          );
        }

        if (response.status === 403) {
          this.forbiddenService.forbid();
        }

        throw response;
      })
    );
  }
}
